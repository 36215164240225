<template>
  <el-main class="rh-form">
    <h1>Ajouter un tutorat</h1>
    <form-tutoratcreate :routes="tutRoutes" />
  </el-main>
</template>
<script>
import FormTutoratcreate from "@/components/rh/formulaires/FormTutoratcreate";

export default {
  components: { "form-tutoratcreate": FormTutoratcreate },
  computed: {
    tutRoutes() {
      return {
        toedit: {
          name: "tutoratedit",
          params: { idtutorat: this.$store.state.tut.tuteurLastCreatedId },
        },
        tolist: { name: "tutorats" },
      };
    },
  },
};
</script>
